.wrap
  width 100%
  height 100%
  background url('../../../static/images/visual_box_border.png') top center no-repeat
  background-size 100% 100%
  overflow hidden
  .header
    margin 20px 40px 0
    height 40px
    line-height 35px
    text-align center
    color #f2fafa
    font-weight bold
    background url('../../../static/images/visual_box_header.gif') top center no-repeat
    background-size 100% 100%
  .content
    padding 0 25px 
    