/* @import '~antd/dist/antd.css'; */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background: #14295a;
}

::-webkit-scrollbar-thumb {
  background: #255893;
  box-shadow: 0;
  border-radius: 2px;
}

/* 交叉角 */
::-webkit-scrollbar-corner {
  background: #14295a; 
}

.cesium-viewer-timelineContainer {
  display: none;
}

.cesium-viewer-animationContainer {
  display: none;
}

.cesium-viewer-bottom {
  display: none;
}

.cesium-viewer-toolbar {
  display: none;
}

.cesium-viewer-fullscreenContainer {
  display: none !important;
}